import React, { useRef } from "react";
import { useEffect, useState } from "react";
import ProviderCard from "./ProviderCard";
import FacilityCard from "./FacilityCard";
import "./App.css";
import SearchIcon from "./search.svg";

const API_URL = "/AHS_Providers_static.json";

const App = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedInputValue, setDebouncedInputValue] = useState("");
  const timeoutValue = 1000;
  const [searchType, setSearchType] = useState("Provider by Name");
  const [providers, setProviders] = useState([]);
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleButtonClick = (searchType) => {
    setSearchType(searchType);
    inputRef.current.focus();
  };

  const searchProviders = async (ProviderType) => {
    const response = await fetch(`${API_URL}`);
    const data = await response.json();

    setProviders(data);
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(searchTerm.toLowerCase());
    }, timeoutValue);

    return () => {
      clearTimeout(delayInputTimeoutId);
    };
  }, [searchTerm, timeoutValue]);
  
  useEffect(() => {
    searchProviders();
  }, []);

  return (
    <div className="app">
      <section>
        <div className="section-title">
          <div>
            <h2>Provider Search</h2>
            <h3>What are you searching for today?</h3>
          </div>
        </div>
        <div className="flex-container">
          <div 
            className={searchType === "Provider by Name" ? 'active' : ''}
            onClick={() => handleButtonClick("Provider by Name")}>
            <div className="search-selection">
              <img
                className="search-icons"
                src="./images/doctor.png"
                alt="icon"
              />
              <p>Provider by Name</p>
            </div>
          </div>
          <div 
            className={searchType === "Provider by Specialty" ? 'active' : ''}
            onClick={() => handleButtonClick("Provider by Specialty")}>
            <div className="search-selection">
              <img
                className="search-icons"
                src="./images/heart-with-pulse.png"
                alt="icon"
              />
              <p>Provider by Specialty</p>
            </div>
          </div>
          <div 
            className={searchType === "Facility by Name" ? 'active' : ''}
            onClick={() => handleButtonClick("Facility by Name")}>
            <div className="search-selection">
              <img
                className="search-icons"
                src="./images/building.png"
                alt="icon"
              />
              <p>Facility by Name</p>
            </div>
          </div>
          <div 
            className={searchType === "Facility by Specialty" ? 'active' : ''}
            onClick={() => handleButtonClick("Facility by Specialty")}>
            <div className="search-selection">
              <img
                className="search-icons"
                src="./images/stethascope.png"
                alt="icon"
              />
              <p>Facility by Specialty</p>
            </div>
          </div>
        </div>
      </section>

      <div className="search">
        <input
          ref={inputRef}
          value={searchTerm}
          onChange={handleInputChange}
          placeholder={"Search for " + searchType}
        />
        <img
          src={SearchIcon}
          alt="search"
          onClick={() => {
            searchProviders(debouncedInputValue);
          }}
        />
      </div>

      {providers?.length > 0 ? (
        <div className="container">
          {providers
            .filter((provider) => {
              return searchType !== "" && debouncedInputValue !== ""
                ? searchType === "Provider by Name"
                  ? provider.ProviderType === 1 &&
                      (provider.Last?.toLowerCase().startsWith(debouncedInputValue) || provider.First?.toLowerCase().startsWith(debouncedInputValue)) 
                  : searchType === "Facility by Name"
                  ? provider.ProviderType === 2 &&
                    provider.Last?.toLowerCase().includes(debouncedInputValue)
                  : searchType === "Provider by Specialty"
                  ? provider.ProviderType === 1 &&
                    provider.IMS_Description?.toLowerCase().includes(
                      debouncedInputValue
                    )
                  : searchType === "Facility by Specialty"
                  ? provider.ProviderType === 2 &&
                    provider.IMS_Description?.toLowerCase().includes(
                      debouncedInputValue
                    )
                  : null
                : null;
            })
            .map((provider) => (
              <div className="info" key={provider.ID}>
                {provider.ProviderType === 1 ? (
                  <ProviderCard provider={provider} />
                ) : (
                  <FacilityCard provider={provider} />
                )}
              </div>
            ))}
        </div>
      ) : (
        <div className="empty">
          <h2>No providers found</h2>
        </div>
      )}
    </div>
  );
};
export default App;
