import React from "react";

const ProviderInfo = ({
  id,
  NPI,
  Last,
  First,
  ProviderType,
  IMS_Code,
  IMS_Description,
  Professional_Designation,
  Taxonomy_1,
  Taxonomy_2,
  AHSProviderLocations,
}) => {
  return (
    <>
      <div className="provider-facility">
        <p className= {ProviderType === 1 ? "icon-provider p-name" : "icon-facility p-name"}>
          {ProviderType === 1 ? Last + ", " + First : Last}
        </p>
        <p>{IMS_Description}</p>
        <p>{ProviderType === 1 ? "Practitioner" : "Facility"}</p>
        <p>NPI: {NPI}</p>
      </div>
      <h4>{AHSProviderLocations.length > 1 ? "LOCATIONS: " : "LOCATION"}</h4>
    </>
  );
};

export default ProviderInfo;
