import React from "react";
import ProviderInfo from "./ProviderInfo";
import LocationInfo from "./LocationInfo";

const ProviderCard = ({
  provider: {
    id,
    NPI,
    Last,
    First,
    ProviderType,
    IMS_Code,
    IMS_Description,
    Professional_Designation,
    Taxonomy_1,
    Taxonomy_2,
    AHSProviderLocations,
  },
}) => {
  return (
    <div className="provider-card">
      
      <ProviderInfo
        {...{
          id,
          NPI,
          First,
          Last,
          ProviderType,
          IMS_Code,
          IMS_Description,
          AHSProviderLocations,
        }}
      />

      <div className="location">
        {AHSProviderLocations.map((location) => (
          <LocationInfo location={location} providerType={ProviderType} specialty={IMS_Description} />
        ))}
      </div>
    </div>
  );
};

export default ProviderCard;
