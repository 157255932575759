import React from "react";
import { PatternFormat } from "react-number-format";

const LocationInfo = ({ location, providerType, specialty }) => {
  const providerOnlyExtraInfo = () => {
    if (providerType === 1) {
      return (
        <>
        <p><span className="lable">Languages Spoken:</span> Unknown</p>
        <p><span className="lable">Specialty:</span> {specialty}</p>
        </>
      );
    }
  }

  return (
    <div className="location-container">
      <div className="location-info">
      <span>
        {location.AHS_PhysicalLocation.Address_1},{" "}
        {location.AHS_PhysicalLocation.City},{" "}
        {location.AHS_PhysicalLocation.State}{" "}
        {location.AHS_PhysicalLocation.Zip}
      </span>
      <br />
      <span>
        Phone:&nbsp;
        <PatternFormat
          displayType="text"
          value={location.AHS_PhysicalLocation.Phone}
          format="(###) ###-####"
        />
      </span>
      <br />
      <span>
        Fax:&nbsp;
        <PatternFormat
          displayType="text"
          value={location.AHS_PhysicalLocation.FAX}
          format="(###) ###-####"
        />
      </span>
      </div>
      <div className="extended-location-info">
        {providerOnlyExtraInfo()}
        <p><span className="lable">Wheelchair Accessible:</span> Unknown</p>
        <p><span className="lable">Accepts New Patients:</span> Unknown</p>

     </div>
   </div>
  );
};





export default LocationInfo;
