import React from "react";
import ProviderInfo from "./ProviderInfo";
import LocationInfo from "./LocationInfo";

const FacilityCard = ({
  provider: {
    id,
    NPI,
    Last,
    First,
    ProviderType,
    IMS_Code,
    IMS_Description,
    Professional_Designation,
    Taxonomy_1,
    Taxonomy_2,
    AHSProviderLocations,
  },
}) => {
  return (
    <div className="provider-info">
      {AHSProviderLocations.map((location) => (
        <div className="provider-card">
          <ProviderInfo
            {...{
              id,
              NPI,
              First,
              Last,
              ProviderType,
              IMS_Code,
              IMS_Description,
              AHSProviderLocations,
            }}
          />
          <div className="location">
            <LocationInfo location={location} providerType={ProviderType} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FacilityCard;
